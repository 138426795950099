import {
  postClientsConnectById,
  postClientsUpload,
  postClientsStartSendImage,
} from '@omnichannel/rchlo-plus-service';
import { combineEpics } from 'redux-observable';
import { switchMap, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';

export const Types = {
  // @@mobileUpload
  MOBILE_UPLOAD_CONNECT: '@@mobileUpload/CONNECT',
  MOBILE_UPLOAD_CONNECTED: '@@mobileUpload/CONNECTED',
  MOBILE_UPLOAD_DISCONNECT: '@@mobileUpload/DISCONNECT',
  MOBILE_UPLOAD_DISCONNECTED: '@@mobileUpload/DISCONNECTED',
  MOBILE_UPLOAD_ERROR: '@@mobileUpload/UPLOAD_ERROR',
  MOBILE_CONNECT_ERROR: '@@mobileUpload/CONNECT_ERROR',
  MOBILE_UPLOAD_IMAGE_SEND: '@@mobileUpload/IMAGE_SEND',
  MOBILE_UPLOAD_IMAGE_SENT: '@@mobileUpload/IMAGE_SENT',
};

// Reducer
const mobileUploadReducer = (
  state = {
    isConnecting: false,
    isConnected: false,
    isDisconnecting: false,
    isDisconnected: true,
    sending: false,
    sent: false,
    error: false,
    connectionError: false,
    Success: false,
    ErrorMessages: [],
  },
  { type, payload },
) => {
  switch (type) {
    case Types.MOBILE_UPLOAD_CONNECT:
      return { ...state, ...payload, isConnecting: true };
    case Types.MOBILE_UPLOAD_CONNECTED:
      return { ...state, ...payload, isConnected: true };
    case Types.MOBILE_UPLOAD_DISCONNECT:
      return { ...state, ...payload, isDisconnecting: true };
    case Types.MOBILE_UPLOAD_DISCONNECTED:
      return { ...state, ...payload, isDisconnected: true };
    case Types.MOBILE_UPLOAD_ERROR:
      return { ...state, ...payload, error: true };
    case Types.MOBILE_CONNECT_ERROR:
      return { ...state, ...payload, connectionError: true };
    case Types.MOBILE_UPLOAD_IMAGE_SEND:
      return { ...state, ...payload, sending: true };
    case Types.MOBILE_UPLOAD_IMAGE_SENT:
      return { ...state, ...payload, sent: true };
    default:
      return state;
  }
};

// Action Creators
export const clientConnect = (payload) => ({ type: Types.MOBILE_UPLOAD_CONNECT, payload });
export const clientDisconnect = (payload) => ({ type: Types.MOBILE_UPLOAD_DISCONNECT, payload });
export const clientConnected = ({ data }) => ({
  type: Types.MOBILE_UPLOAD_CONNECTED,
  payload: data,
});
export const clientDisconnected = (payload) => ({
  type: Types.MOBILE_UPLOAD_DISCONNECTED,
  payload,
});
export const clientError = (payload) => ({ type: Types.MOBILE_CONNECT_ERROR, payload });
export const sendImage = (payload) => ({ type: Types.MOBILE_UPLOAD_IMAGE_SEND, payload });
export const sentImage = (payload) => ({ type: Types.MOBILE_UPLOAD_IMAGE_SENT, payload });
export const sendImageError = (payload) => ({ type: Types.MOBILE_UPLOAD_ERROR, payload });

// Epics
const connectEpic = (action$) =>
  action$.ofType(Types.MOBILE_UPLOAD_CONNECT).pipe(
    distinctUntilChanged(),
    switchMap(({ payload: { id } }) =>
      from(postClientsConnectById(id)).pipe(
        distinctUntilChanged(),
        map(clientConnected),
        catchError(() => of(clientError())),
      ),
    ),
  );

const uploadEpic = (action$) =>
  action$.ofType(Types.MOBILE_UPLOAD_IMAGE_SEND).pipe(
    distinctUntilChanged(),
    switchMap(({ payload: { connectionId, imageBinary, imageHeaders } }) =>
      forkJoin({
        start: from(postClientsStartSendImage(connectionId)),
        upload: from(
          postClientsUpload({
            connectionId,
            imageBinary,
            imageHeaders,
          }),
        ),
      }).pipe(
        distinctUntilChanged(),
        map(sentImage),
        catchError(() => of(sendImageError())),
      ),
    ),
  );

export const clientsEpic = combineEpics(connectEpic, uploadEpic);

const clientsReducer = {
  mobileUploadState: mobileUploadReducer,
};

export default clientsReducer;
