export const WindowSize = () => ({ height: window.innerHeight, width: window.innerWidth });

export const $ = (selector) => document.querySelector(selector);

export const $all = (selector) => document.querySelectorAll(selector);

const createClientXYObject = (x, y) => {
  return { clientX: x, clientY: y };
};
const preventDefault = () => {};
export const createTouchEventObject = ({ x, y, ...rest }) => {
  return {
    touches: [createClientXYObject(x, y)],
    preventDefault,
    ...rest,
  };
};

export const createMouseEventObject = ({ x, y, ...rest }) => {
  return {
    ...createClientXYObject(x, y),
    preventDefault,
    ...rest,
  };
};

export const createStartTouchEventObject = ({ x = 0, y = 0, ...rest }) => {
  return {
    touches: [createClientXYObject(x, y)],
    preventDefault,
    ...rest,
  };
};

export const createMoveTouchEventObject = ({ x = 0, y = 0 }) => {
  return { changedTouches: [createClientXYObject(x, y)] };
};

// eslint-disable-next-line no-undef
export const ReduxCompose = () => window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const queryStringFormatter = (search) => {
  const obj = {};
  search
    .replace('?', '')
    .split('&')
    .forEach((kv) => {
      const [k, v] = kv.split('=');
      obj[k] = v;
    });
  return obj;
};

export const fileReader = (methods) => (file) => {
  let reader;
  
  if (methods) {
    reader = new FileReader();
    Object.keys(methods).forEach((key) => {
      reader.addEventListener(key, methods[key]);
    });
  }

  return (
    file &&
    reader.readAsDataURL(file) &&
    (() => {
      reader.abort();
      Object.keys(methods).forEach((key) => {
        reader.removeEventListener(key, methods[key]);
      });
      reader = null;
    })
  );
};
