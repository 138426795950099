// @flow
import React, { lazy, Suspense } from 'react';
import { shape } from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const routes = [
  {
    path: '/MobileUpload/:id',
    exact: true,
    component: lazy(() => import('./Mobile/MobileImageUpload')),
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../shared/components/Redirect/WithQueryString')),
  },
];

const Routes = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Suspense fallback={<></>}>
        {routes.map(({ path, exact, component }) => (
          <Route path={path} exact={exact} component={component} key={path} />
        ))}
      </Suspense>
    </Switch>
  </Router>
);

Routes.propTypes = {
  history: shape({}),
};

export default connect(({ history }) => ({ history }))(Routes);
